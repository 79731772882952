import React, { useEffect, useState } from "react";
import { FormGroup, FormControlLabel, Checkbox } from "@mui/material";

const InlineCheckbox = ({
  items,
  sectionIndex,
  rowIndex,
  cellIndex,
  handleInputConfigTableCellValue,
}) => {
  const [localItems, setLocalItems] = useState(items);

  const handleChange = (index, value) => {
    let items = [...localItems];
    let valueString = []
    for (let i = 0; i < items.length; i++) {
      if (i === index) {
        items[index].value = value;
        valueString.push('true')
      }
      else{
        items[i].value = 'false'
        valueString.push('false')
      }
    }

    setLocalItems([...items]);

    value = valueString.join(",");
    handleInputConfigTableCellValue(sectionIndex, rowIndex, cellIndex, value);
  };

  useEffect(() => {
    setLocalItems([...items]);
  }, [items]);
  return (
    <FormGroup row style={{ marginTop: "-10px" }}>
      {localItems.map((item, index) => (
        <FormControlLabel
          key={item.name}
          control={
            <Checkbox
              checked={item.value === "true"}
              onChange={(e) => {
                handleChange(index, e.target.checked.toString());
              }}
              name={item.name}
              color="primary"
            />
          }
          label={item.label}
        />
      ))}
    </FormGroup>
  );
};

export default InlineCheckbox;
