import { Box, CircularProgress, Modal } from "@mui/material";

const DownloadPdfLoaderModal = ({ loading, name }) => {
  return (
    <Modal open={loading}>
      <Box
        style={{
          width: "100%",
          height: "100vh",
          border: "2px solid cadetblue",
          padding: "30px",
          textAlign: "center",
          display: "grid",
          placeItems: "center",
        }}
      >
        <div>
          <CircularProgress thickness={5} color="primary" size="6rem" />
          <h5 style={{ color: "white", fontWeight: "900", fontSize: "2.5rem" }}>
            {name} Download In Progress...
          </h5>
        </div>
      </Box>
    </Modal>
  );
};

export default DownloadPdfLoaderModal;
