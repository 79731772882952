import React, { useEffect } from "react";
import { Autocomplete, Button, Paper, Toolbar } from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import { Link, useParams } from "react-router-dom";
import CommonForm from "../../utils/components/commonForm";
import axiosWithToken from "../../utils/components/axiosTokenConfig";
import { BASE_URL } from "../../global";

const EditShop = () => {
    const [inputStates, setInputStates] = React.useState({});
    const params = useParams();
  
    const updateInputObject = (key, value) => {
      setInputStates((prevInputStates) => ({
        ...prevInputStates,
        [key]: value,
      }));
    };
  
    const handleSubmit = () => {
      let url = BASE_URL;
      if (params.id) {
        axiosWithToken
          .patch(url + `shops/${params.id}`, inputStates)
          .then((res) => {
            setTimeout(refresh, 500);
            toast("Shop edit successfully !");
          })
          .catch((error) => {
            toast.error("Something Went Wrong!");
          });
      } else {
        axiosWithToken
          .post(url + "shops", inputStates)
          .then((res) => {
            setTimeout(refresh, 500);
            toast("Shop created successfully !");
          })
          .catch((error) => {
            toast.error("Something Went Wrong!");
          });
      }
    };
  
    const fetchShop = (inpt) => {
      let url = BASE_URL;
      axiosWithToken
        .get(url + `shops/${params.id}`)
        .then((res) => {
          let dataObject = res.data[0];
  
          let newData = {
            ...inputStates,
            ...dataObject,
          };
          dataObject && setInputStates(newData);
        })
        .catch((error) => {
          toast.error("Something Went Wrong!");
        });
    };
  
    var refresh = () => {
      window.location.reload(false);
    };
  
    useEffect(() => {
      if (params.id) fetchShop();
    }, [params.id]);
  
    const inputFields = [
      { type: "TextField", label: "Shop Name", stateKey: "name" },
    ];
  
    return (
      <>
        <Paper sx={{ mt: 2, p: 4 }}>
          <CommonForm
            inputStates={inputStates}
            updateInputObject={updateInputObject}
            inputFields={inputFields}
          />
  
          <Toolbar
            style={{
              padding: "0px",
              overflow: "auto",
              display: "flex",
              justifyContent: "end",
            }}
          >
            <Button
              variant="contained"
              size="small"
              sx={{ m: 0 }}
              onClick={() => {
                handleSubmit();
              }}
            >
              {params.id ? "Update" : "Save"}
            </Button>
          </Toolbar>
        </Paper>
      </>
    );
}

export default EditShop