import React from "react";
import { Box, Grid, Paper } from "@mui/material";
import Image from "mui-image";

const extractImageUrls = (formConfig) => {
  const images = [];
  let label = "";
  formConfig?.forEach((section) => {
    section?.inputList?.forEach((row) => {
      row?.cells?.forEach((cell) => {
        if (cell?.label?.length > 0) {
          label = cell?.label;
        }

        if (cell?.type === "image" && cell?.value?.length > 0) {
          images.push({ url: cell?.value, label: label });
        }
      });
    });
  });
  return images;
};

const DefectiveImages = (props) => {
  const { formConfig } = props;

  const images = extractImageUrls(formConfig);
  return (
    <div style={{ margin: "20px", pageBreakBefore: "auto", width: "100%" }}>
      <Grid container spacing={2} width="100%">
        {images.map((image, index) => (
          
          // <Grid
          //   item
          //   xs={6}
          //   sm={6} //6,12
          //   md={4}
          //   lg={3}
          //   key={index}
          //   style={{ padding: "10px" }}
          // >
          <Grid item xs={12} sm={6} md={4} lg={3} key={index}
          style={{padding:"10px"}}
          >
            <Box>
              <h5
                style={{
                  textAlign: "left",
                  fontSize: "21px", 
                  marginBottom:"10px",
                  "@media (max-width: 600px)": {
                    fontSize: "8px", 
                  },
                }}
              >
                {index + 1}. {image.label}
              </h5>
              <Image
                src={image.url}
                alt={`Image ${index + 1}`}
                style={{
                  objectFit: "cover",
                  maxWidth: "100%",
                  // maxHeight:"200px"
                  
                }}
              />
            </Box>
          </Grid>
        ))}
      </Grid>

      {/* <Grid container spacing={2}>
        {images.map((image, index) => (
          <Grid item xs={4} key={index}>
            <Paper>
              <h5 style={{ textAlign: "left" }}>
                {index + 1}. {image.label}
              </h5>
              <Image
                src={image.url}
                alt={`Image ${index + 1}`}
                style={{
                  objectFit: "cover",
                  maxWidth: "400px",
                }}
              />
            </Paper>
          </Grid>
        ))}
      </Grid> */}
    </div>
  );
};

export default DefectiveImages;
