import React, { useEffect, useState, useRef, useContext } from "react";
import Grid from "@mui/material/Grid";
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  MenuItem,
  Modal,
  Select,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import html2pdf from "html2pdf.js";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import GridInput from "./sections/GridInput";
import DefectiveImages from "./sections/DefectiveImages";
import "./editChecklist.css";
import { BASE_URL, DOMAIN } from "../../global";
import { toast } from "react-toastify";
import { useParams, useNavigate } from "react-router-dom";
// import spartanLerrerHead from "./../image/SpartanLetterHead.jpg";
// import footerImage from "./../image/footer.png";
import SignatureCanvas from "../../utils/components/SignatureCanvas";
import sampleTemplate from "./sampleTemplate.json";
import QRCode from "react-qr-code";
import moment from "moment/moment";
import axiosWithToken from "../../utils/components/axiosTokenConfig";
import axios from "axios";
// import { PrintContext } from "./PrintContext";

const generateRandomQRCode = () => {
  // Generate a random number between 100000 and 999999 (you can adjust range as needed)
  return Math.floor(Math.random() * 900000 + 100000);
};

export default function DisplayTemplate(props) {
  const elementRef = useRef(null);
  const navigate = useNavigate();
  // const { setIsPrinting ,isPrinting } = useContext(PrintContext);
  const { reportID, templateID, isPrint } = useParams();

  const [formConfig, setFormConfig] = useState(sampleTemplate);
  const [templateDetails, setTemplateDetails] = useState({
    name: "Form Template",
  });
  const [reportDetails, setReportDetails] = useState({
    name: null,
    qrCodeNo: null,
    reportNo: null,
  });
  const [clientList, setClientList] = useState([]);
  const [sectionsVisible, setSectionsVisible] = useState(0);
  const userId = localStorage.getItem("id");
  const userType = localStorage.getItem("type");
  const userModulesString = localStorage.getItem("modules");
  const userModules = userModulesString ? userModulesString.split(",") : [];
  const uppercaseUserModules = userModules.map((module) =>
    module.toUpperCase()
  );

  const [loading, setLoading] = React.useState(false);
  const [printing, setPrinting] = useState(false);

  const handlePrint = () => {
    setPrinting(true);
    const printContent = document.getElementById("formToPrint");
    const originalContents = document.body.innerHTML;

    if (printContent) {
      document.body.innerHTML = elementRef.current.innerHTML;
      window.print();
      document.body.innerHTML = originalContents;
      window.location.reload();
    }
  };
  console.log(printing,reportDetails?.qrCodeNo)
  // console.log("formConfig", formConfig);
  const handleInputConfigTableCellValue = (
    sectionIndex,
    rowIndex,
    cellIndex,
    value
  ) => {
    setFormConfig((prevConfig) => {
      const updatedConfig = [...prevConfig];
      updatedConfig[sectionIndex].inputList[rowIndex].cells[cellIndex][
        "value"
      ] = value;
      return updatedConfig;
    });
  };

  const generateQRCode = () => {
    const randomQRCode = generateRandomQRCode();
    setReportDetails((prevState) => ({
      ...prevState,
      qrCodeNo: randomQRCode,
    }));
  };

  const handleFileUpload = (sectionIndex, rowIndex, cellIndex, file) => {
    const MAX_FILE_SIZE = 3000000; // 3MB in bytes

    // Check file size
    if (file.size > MAX_FILE_SIZE) {
      const reader = new FileReader();

      reader.onload = (event) => {
        const img = new Image();
        img.src = event.target.result;

        img.onload = () => {
          const canvas = document.createElement("canvas");
          const ctx = canvas.getContext("2d");

          // Calculate new dimensions to maintain the aspect ratio
          const maxWidth = 800;
          const maxHeight = 600;
          let width = img.width;
          let height = img.height;

          if (width > maxWidth) {
            height *= maxWidth / width;
            width = maxWidth;
          }

          if (height > maxHeight) {
            width *= maxHeight / height;
            height = maxHeight;
          }

          canvas.width = width;
          canvas.height = height;

          ctx.drawImage(img, 0, 0, width, height);

          // Convert canvas to Blob
          canvas.toBlob((blob) => {
            const compressedFile = new File([blob], file.name, {
              type: file.type,
            });

            // Upload the compressed file
            uploadCompressedFile(
              sectionIndex,
              rowIndex,
              cellIndex,
              compressedFile
            );
          }, file.type);
        };
      };

      reader.readAsDataURL(file);
    } else {
      // File is smaller than 1MB, upload as is
      uploadCompressedFile(sectionIndex, rowIndex, cellIndex, file);
    }
  };
  var refresh = () => {
    window.location.reload(false);
  };

  const uploadCompressedFile = (sectionIndex, rowIndex, cellIndex, file) => {
    setFormConfig((prevConfig) => {
      const updatedConfig = [...prevConfig];
      updatedConfig[sectionIndex].inputList[rowIndex].cells[cellIndex][
        "fileName"
      ] = file.name;
      return updatedConfig;
    });

    const data = new FormData();
    data.append("fileToUpload", file);
    setLoading(true);
    axiosWithToken
      .post(BASE_URL + `fileUpload/fileUpload.php`, data)
      .then((res) => {
        if (res.data.includes("[ERROR]")) {
          toast.error(
            "Error: Your image size is too large to upload.( Add less than 3 MB size Image )",
            {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            }
          );
        } else {
          toast("File Uploaded successfully !");

          setFormConfig((prevConfig) => {
            const updatedConfig = [...prevConfig];
            updatedConfig[sectionIndex].inputList[rowIndex].cells[cellIndex][
              "value"
            ] = res.data;
            updatedConfig[sectionIndex].inputList[rowIndex].cells[cellIndex][
              "fileName"
            ] = file.name;
            return updatedConfig;
          });
        }
      })
      .catch((error) => {
        console.error("Error uploading file:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const fetchformReport = async (id) => {
    setLoading(true);
    try {
      const res = await axiosWithToken.get(BASE_URL + `reportValues/${id}`);
      const data = res.data[0];

      const response = await axios.get(data?.templateValues);
      setReportDetails({
        ...reportDetails,
        reportNo: data.reportNo,
        qrCodeNo: data.qrCodeNo,
      });
      fetchTemplate(data?.templateId);

      setFormConfig(response.data);
    } catch (error) {
      console.log("Failed to fetch template: ", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchTemplate = (id) => {
    try {
      setLoading(true);
      axiosWithToken
        .get(BASE_URL + `templates/${id}`)
        .then((res) => {
          let data = res.data[0];
          axios.get(data?.url).then((respose) => {
            setFormConfig(respose.data);
          });

          let _templateDetails = {
            name: data.name,
          };
          setTemplateDetails({ ...templateDetails, ..._templateDetails });
          setReportDetails({ ...reportDetails, name: data.name });
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (e) {
      console.log("Failed to fetch checklist template: ", e);
    }
  };

  const submitReport = async () => {
    setReportDetails({
      ...reportDetails,
      date: moment(Date.now()).format("YYYY-MM-DD"),
    });
    let _sectionVisible = sectionsVisible;
    if (_sectionVisible < formConfig.length) {
      _sectionVisible = _sectionVisible + 1;
      setSectionsVisible(_sectionVisible);
    } else {
      // moment(Date.now()).format("YYYY-MM-DD")
      if (!window.confirm("Do you want to submit the Report ?")) {
        return;
      }
    }

    try {
      const formConfigJson = JSON.stringify(formConfig);
      const blob = new Blob([formConfigJson], { type: "application/json" });

      const data = new FormData();
      data.append("fileToUpload", blob, "formConfig.json");

      const response = await axiosWithToken.post(
        BASE_URL + "fileUpload/fileUpload.php",
        data
      );

      if (response.status === 200) {
        const reportData =
          reportID < 1
            ? {
                templateId: templateID,
                templateValues: response.data,
                reportNo: reportDetails?.reportNo,
                qrCodeNo: reportDetails?.qrCodeNo,
              }
            : {
                templateValues: response.data,
                reportNo: reportDetails?.reportNo,
                qrCodeNo: reportDetails?.qrCodeNo,
              };

        const reportEndpoint =
          reportID < 1
            ? BASE_URL + "reportValues"
            : BASE_URL + `reportValues/${reportID}`;

        const reportResponse = await (reportID < 1
          ? axiosWithToken.post(reportEndpoint, reportData)
          : axiosWithToken.patch(reportEndpoint, reportData));

        toast(
          reportResponse.status === 200
            ? "Report created/updated successfully!"
            : "Failed to create/update Report."
        );
        if (reportResponse.status === 200) {
          setTimeout(refresh, 500);
        }
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("Something went wrong!");
    }

    // if (reportID < 1) {
    //   try {
    //     let data = {
    //       checklistDetails: JSON.stringify({
    //         ...reportDetails,
    //         sectionsVisible: _sectionVisible,
    //       }),
    //       configValues: JSON.stringify(formConfig),
    //     };
    //       // axiosWithToken.post(BASE_URL + "checklists", data).then((res) => {
    //       //   navigate(`/checklist/edit/${res.data.insertId}/0`);
    //       //   toast("Report created successfully !");
    //       // });

    //   } catch (e) {
    //     console.log("Failed to create Report: ", e);
    //   }
    // } else {
    //   try {
    //     let data = {
    //       checklistDetails: JSON.stringify({
    //         ...reportDetails,
    //         sectionVisible: _sectionVisible,
    //       }),
    //       configValues: JSON.stringify(formConfig),
    //     };
    //     axiosWithToken
    //       .patch(BASE_URL + `checklists/${reportID}`, data)
    //       .then((res) => {
    //         toast("template updated successfully !");
    //         // navigate(`/checklist/edit/${reportID}/0`);
    //       });
    //   } catch (e) {
    //     console.log("Failed to submit checklist template: ", e);
    //   }
    // }
  };

  const fetchValueFromTable = (tableName) => {
    setLoading(true);
    axiosWithToken
      .get(`${BASE_URL}${tableName}`)
      .then((res) => {
        setClientList([...res.data]);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const isSectionValid = (sectionConfig, sectionIndex, formConfig) => {
    const section = formConfig[sectionIndex];

    for (const input of sectionConfig.inputList) {
      for (const cell of input.cells) {
        if (!cell.value || cell.value == "") {
          return false;
        }
      }
    }

    return true;
  };

  useEffect(() => {
    setReportDetails({
      ...reportDetails,
      sectionsVisible: sectionsVisible,
    });
  }, [sectionsVisible]);

  useEffect(() => {
    if (reportID > 0) {
      fetchformReport(reportID);
    } else if (templateID > 0) {
      fetchTemplate(templateID);
      generateQRCode();
    }
  }, [reportID, templateID]);

  useEffect(() => {
    fetchValueFromTable("clients");
  }, []);

  const generatePDF = async () => {
    const element = elementRef.current;
    let pdf = await html2pdf()
      .from(element)
      .set({
        margin: [1, 1, 1, 1],
        filename: `checklist.pdf`,

        pagebreak: { after: "section", mode: ["css", "legacy"] },
        html2canvas: {
          dpi: 10,
          scale: 1,
          letterRendering: true,
          useCORS: true,
        },

        jsPDF: {
          orientation: "portrait",
          unit: "pt",
          format: "a4",
        },
      })
      .toPdf()
      .get("pdf");

    const totalPages = pdf.internal.getNumberOfPages();
    for (let i = 1; i <= totalPages; i++) {
      pdf.setFontSize(9);
      pdf.setTextColor(0, 0, 0);
      pdf.setFont("Courier");
      pdf.setPage(i);
    }

    pdf.save();
  };

  const renderSections = (formConfig) => {
    let sectionRows = [];

    // formConfig.forEach((section, index) =>
    for (let index = 0; index < formConfig.length; index++) {
      if (index > sectionsVisible) break;
      let section = formConfig[index];
      let sectionOutput = null;
      if (section.sectionType === "gridInput") {
        sectionOutput = (
          <GridInput
            id={"section" + index}
            config={section}
            sectionIndex={index}
            handleInputConfigTableCellValue={handleInputConfigTableCellValue}
            handleFileUpload={handleFileUpload}
          />
        );
      } else if (
        section.sectionType === "imageGroup" ||
        section.sectionType === "defectiveImages"
      ) {
        sectionOutput = (
          <Box style={{ border: "1px solid black" }}>
            <DefectiveImages formConfig={formConfig} />
          </Box>
        );
      }

      sectionRows.push(
        <TableRow>
          {section?.sectionName && (
            <h5
              style={{
                textAlign: "left",
                padding: "5px",
                paddingTop: "10px",
                border: "1px solid black",
                pageBreakInside: "avoid",
              }}
            >
              <b>{section?.sectionName}</b>
            </h5>
          )}
          {sectionOutput}
        </TableRow>
      );
    }
    return sectionRows;
  };

  // Function to extract the number after "FM" from reportDetails.name
  function getFMNumber(name) {
    const matches = name.match(/FM(\d+)/);
    return matches ? matches[1] : ""; // Return the captured number or an empty string if not found
  }

  const renderStaticSection = () => {
    return [
      <TableRow>
        <TableCell
          align="center"
          style={{ backgroundColor: "#ed7d31", border: "none" }}
        >
          <h4
            style={{
              textTransform: "uppercase",
              fontSize: "1.1rem",
              marginTop: "5px",
            }}
          >
            <strong>{reportDetails.name}</strong>
          </h4>
          {reportDetails && reportDetails.name?.includes("11") ? (
            <>
              <h6 style={{ fontSize: "0.9rem" }}>
                <b>(See Rule 62 and 64)</b>
              </h6>
              <h6 style={{ fontSize: "0.9rem", marginBottom: "5px" }}>
                <b>
                  REPORT OF EXAMINATION OF HOIST, LIFT, LIFTING MACHINE, CHAINS,
                  ROPES AND LIFTING TACKLE
                </b>
              </h6>
            </>
          ) : (
            <>
              <h6 style={{ fontSize: "0.9rem" }}>
                <b>(See Rule 65 And 73-A)</b>
              </h6>
              <h6 style={{ fontSize: "0.9rem", marginBottom: "5px" }}>
                <b>
                  REPORT OF EXAMINATION OF PRESSURE PLANT / VESSEL / GAS HOLDER
                </b>
              </h6>
            </>
          )}
        </TableCell>
      </TableRow>,
      <TableRow>
        <TableCell align="right" sx={{ border: "none" }}>
          {reportDetails && reportDetails.name ? (
            <div>
              <h5 style={{ fontSize: "0.6rem" }}>
                <b>Report No:</b>
                {printing ? (
                  reportDetails.reportNo || ""
                ) : (
                  <TextField
                    variant="outlined"
                    size="small"
                    value={reportDetails.reportNo || ""}
                    onChange={(event) =>
                      setReportDetails((prevState) => ({
                        ...prevState,
                        reportNo: event.target.value,
                      }))
                    }
                  />
                )}
              </h5>
            </div>
          ) : null}
        </TableCell>
      </TableRow>,
    ];
  };

  function getFormattedDate() {
    const today = new Date();
    const formattedDate = today.toLocaleDateString("en-GB");
    return formattedDate;
  }

  function getNextYearDate() {
    const today = new Date();
    const nextYear = new Date(today);
    nextYear.setFullYear(today.getFullYear() + 1);
    const formattedNextYearDate = nextYear.toLocaleDateString("en-GB");
    return formattedNextYearDate;
  }

  const renderChecklistForm = () => {
    return (
      <div
        id="formToPrint"
        ref={elementRef}
        style={{ width: "100%", fontSize: "16px", margin: "auto" }}
      >
        <div
          style={{
            width: "96%",
            fontSize: "16px",
            border: "1px solid black",
            margin: "auto",
            padding: "7px",
          }}
        >
          <Table>
            <TableBody>
              {renderStaticSection()}
              {renderSections(formConfig)}
            </TableBody>
          </Table>
          {sectionsVisible === formConfig.length && (
            <div style={{ textAlign: "left", fontSize: "0.9rem" }}>
              <br />
              <h6>
                I/We certify that on <b>{getFormattedDate()}</b> I/ We
                thoroughly examined the hoist, lift, lifting machine, ropes and
                lifting tackles and details of examinations / test carried out
                is enclosed herewith that the above is a correct report of the
                result.
              </h6>
              <br />
              <b>Next Due Date:- {getNextYearDate()}</b>
            </div>
          )}

          {sectionsVisible == formConfig.length && (
            <table style={{ pageBreakInside: "avoid", width: "100%" }}>
              <tbody>
                <tr>
                  <td
                    style={{
                      borderRight: "1px solid black",
                      backgroundColor: "#fff2cd",
                      width: "37%",
                    }}
                  >
                    <div
                      style={{
                        marginLeft: "10px",
                        marginTop: "2px",
                        marginBottom: "2px",
                        fontSize: "0.6rem",
                        textAlign: "right",
                        paddingRight: "5px",
                      }}
                    >
                      <b>Signature of Competent Person</b>
                    </div>
                  </td>
                  <td
                    style={{
                      borderRight: "1px solid black",
                      backgroundColor: "#d9d9d9",
                      width: "1.3%",
                      textAlign: "center",
                    }}
                  >
                    :
                  </td>
                  <td style={{ width: "37%" }}>
                    <div
                      style={{
                        marginLeft: "10px",
                        marginRight: "5px",
                        marginTop: "2px",
                        marginBottom: "2px",
                        fontSize: "0.6rem",
                      }}
                    ></div>
                  </td>
                  <td style={{ width: "25%" }} rowSpan={5}>
                    <div
                      style={{
                        marginLeft: "10px",
                        marginRight: "5px",
                        marginTop: "2px",
                        marginBottom: "2px",
                        fontSize: "0.6rem",
                      }}
                    ></div>
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      borderRight: "1px solid black",
                      backgroundColor: "#fff2cd",
                    }}
                  >
                    <div
                      style={{
                        marginLeft: "10px",
                        marginTop: "2px",
                        marginBottom: "2px",
                        fontSize: "0.6rem",
                        textAlign: "right",
                        paddingRight: "5px",
                      }}
                    >
                      <b>Name of Competent Person </b>
                    </div>
                  </td>
                  <td
                    style={{
                      borderRight: "1px solid black",
                      backgroundColor: "#d9d9d9",
                      width: "15px",
                      textAlign: "center",
                    }}
                  >
                    :
                  </td>
                  <td>
                    <div
                      style={{
                        marginLeft: "10px",
                        marginRight: "5px",
                        marginTop: "2px",
                        marginBottom: "2px",
                        fontSize: "0.6rem",
                      }}
                    ></div>
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      borderRight: "1px solid black",
                      backgroundColor: "#fff2cd",
                    }}
                  >
                    <div
                      style={{
                        marginLeft: "10px",
                        marginTop: "2px",
                        marginBottom: "2px",
                        fontSize: "0.6rem",
                        textAlign: "right",
                        paddingRight: "5px",
                      }}
                    >
                      <b>Qualification</b>
                    </div>
                  </td>
                  <td
                    style={{
                      borderRight: "1px solid black",
                      backgroundColor: "#d9d9d9",
                      width: "15px",
                      textAlign: "center",
                    }}
                  >
                    :
                  </td>
                  <td>
                    <div
                      style={{
                        marginLeft: "10px",
                        marginRight: "5px",
                        marginTop: "2px",
                        marginBottom: "2px",
                        fontSize: "0.6rem",
                      }}
                    ></div>
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      borderRight: "1px solid black",
                      backgroundColor: "#fff2cd",
                    }}
                  >
                    <div
                      style={{
                        marginLeft: "10px",
                        marginTop: "2px",
                        marginBottom: "2px",
                        fontSize: "0.6rem",
                        textAlign: "right",
                        paddingRight: "5px",
                      }}
                    >
                      <b>Address</b>
                    </div>
                  </td>
                  <td
                    style={{
                      borderRight: "1px solid black",
                      backgroundColor: "#d9d9d9",
                      width: "15px",
                      textAlign: "center",
                    }}
                  >
                    :
                  </td>
                  <td>
                    <div
                      style={{
                        marginLeft: "10px",
                        marginRight: "5px",
                        marginTop: "2px",
                        marginBottom: "2px",
                        fontSize: "0.6rem",
                      }}
                    ></div>
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      borderRight: "1px solid black",
                      backgroundColor: "#fff2cd",
                    }}
                  >
                    <div
                      style={{
                        marginLeft: "10px",
                        marginTop: "2px",
                        marginBottom: "2px",
                        fontSize: "0.6rem",
                        textAlign: "right",
                        paddingRight: "5px",
                      }}
                    >
                      <b>Date</b>
                    </div>
                  </td>
                  <td
                    style={{
                      borderRight: "1px solid black",
                      backgroundColor: "#d9d9d9",
                      width: "15px",
                      textAlign: "center",
                    }}
                  >
                    :
                  </td>
                  <td>
                    <div
                      style={{
                        marginLeft: "10px",
                        marginRight: "5px",
                        marginTop: "2px",
                        marginBottom: "2px",
                        fontSize: "0.6rem",
                      }}
                    ></div>
                  </td>
                </tr>
              </tbody>
            </table>
          )}
          <div>
            <Box
              sx={{
                display: "none",
                displayPrint: "block",
              }}
            >
              {/* <img
              src={footerImage}
              alt="Image description"
              style={{ width: "100%" }}
            /> */}
            </Box>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div style={{ paddingBottom: "20px" }}>
      <Grid container spacing={2}>
        <Grid
          item
          xs={12}
          style={{ display: "flex", justifyContent: "flex-start" }}
        ></Grid>
      </Grid>
      <hr />
      <div>{renderChecklistForm()}</div>
      <hr />
      <Grid container spacing={2}>
        <Grid
          item
          xs={12}
          style={{ display: "flex", justifyContent: "flex-start" }}
        >
          <Button
            variant="contained"
            onClick={submitReport}
            //  disabled={isPrinting}
          >
            {sectionsVisible < formConfig.length ? "next / save" : "save"}
          </Button>
          &nbsp;&nbsp;&nbsp;
          <Button variant="contained" onClick={handlePrint}>
            print
          </Button>
          &nbsp;&nbsp;&nbsp;
          <Button variant="contained" onClick={() => {}}>
            cancel
          </Button>
        </Grid>
      </Grid>
      <Modal
        open={loading}
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Box
          style={{
            width: "100%",
            height: "100vh",
            bgcolor: "background.paper",
            border: "2px solid cadetblue",
            padding: "30px",
            textAlign: "center",
            display: "grid",
            placeItems: "center",
          }}
        >
          <CircularProgress color="secondary" size={200} />
        </Box>
      </Modal>
    </div>
  );
}
