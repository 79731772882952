import React, { useEffect, useState } from "react";
import {
  FormGroup,
  FormControlLabel,
  Checkbox,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import axios from "axios";
import { BASE_URL } from "../../../global";

const CustomDropdown = ({
  cell,
  sectionIndex,
  rowIndex,
  cellIndex,
  handleInputConfigTableCellValue,
}) => {
  const [valueOptions, setValueOptions] = React.useState([]);

  // const handleChange = (event, value) => {
  //   handleInputConfigTableCellValue(
  //     sectionIndex,
  //     rowIndex,
  //     cellIndex,
  //     value
  //   );
  // };

  const fetchValueFromTable = (tableName, columnName) => {
    axios.get(`${BASE_URL}${tableName}?_fields=${columnName}`).then((res) => {
      let data = res.data;
      let valueOptions = [];
      data.forEach((item) => {
        valueOptions.push(item[columnName]);
      });

      setValueOptions([...valueOptions]);
    });
  };

  useEffect(() => {
    let tableName, columnName;
    if (cell?.attributes && typeof cell?.attributes?.[0] === 'string'&& cell?.attributes?.[0]?.startsWith("table")) {
     
      let config = cell?.attributes?.[0]?.split(".");
      tableName = config[1];
      columnName = config[2];
      fetchValueFromTable(tableName, columnName);
    }
  }, [cell?.attribute]); 
  
console.log("cell",cell)
  return (
    <Box>
    <FormControl fullWidth variant="outlined">
      <Select
        labelId="field-type-label"
        defaultValue={cell.value || ""}
        onChange={(event) => {
          const value = event.target.value;
          handleInputConfigTableCellValue(sectionIndex, rowIndex, cellIndex, value);
        }}
        label="Field Type"
        size="small"
      >
        {valueOptions.map((option, index) => (
          <MenuItem key={index} value={option}>
            {option}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  </Box>


  );
};

export default CustomDropdown;
