import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  ListItemButton,
  ListItemIcon,
  Modal,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React from "react";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { toast } from "react-toastify";
import { BASE_URL } from "../../global";
import axiosWithToken from "../../utils/components/axiosTokenConfig";

export default function ResetPassword({ isMobile }) {
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [userData, setUserData] = React.useState({
    username: "",
    password: "",
    confirmPassword: "",
  });
  const [passwordVisibility, setPasswordVisibility] = React.useState({
    password: false,
    confirmPassword: false,
  });
  const storage_userID = localStorage.getItem("id");
  const handleModalOpen = () => {
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const handleInputChange = (field) => (event) => {
    setUserData({ ...userData, [field]: event.target.value });
  };

  const togglePasswordVisibility = (field) => {
    setPasswordVisibility((prevVisibility) => ({
      ...prevVisibility,
      [field]: !prevVisibility[field],
    }));
  };

  const handleSaveChanges = async () => {
    if (!userData.password || !userData.confirmPassword) {
      toast.error("Please fill in both password and confirm password fields");
      return;
    }
    if (userData.password !== userData.confirmPassword) {
      toast.error("New password and confirm password do not match");
      return;
    }
    try {
      const response = await axiosWithToken.patch(
        BASE_URL + `/users/${storage_userID}`,
        {
          password: userData.password,
        }
      );

      if (response.status === 200) {
        toast.success("Password updated successfully");
        handleModalClose();
      } else {
        console.error("Failed to update password");
      }
    } catch (error) {
      console.error("Error updating password:", error);
    }
  };

  const passwordChangeModal = () => {
    return (
      <Modal
        open={isModalOpen}
        onClose={handleModalClose}
        aria-labelledby="edit-profile-modal"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 550,
            "@media (max-width: 600px)": {
              width: "80%", // Adjust the width for screens up to 768px wide
            },
            "@media (max-width: 480px)": {
              width: "90%", // Adjust the width for screens up to 480px wide
            },
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 2,
            maxHeight: "550px",
            overflow: "auto",
            padding: "30px",
          }}
        >
          <Typography variant="h6" gutterBottom>
            Edit Password
          </Typography>
          <TextField
            disabled
            value={userData.username || ""}
            fullWidth
            margin="normal"
          />
          <TextField
            label="New Password"
            type={passwordVisibility.password ? "text" : "password"}
            value={userData.password}
            onChange={handleInputChange("password")}
            fullWidth
            margin="normal"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    edge="end"
                    onClick={() => togglePasswordVisibility("password")}
                  >
                    {passwordVisibility.password ? (
                      <VisibilityIcon />
                    ) : (
                      <VisibilityOffIcon />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <TextField
            label="Confirm Password"
            type={passwordVisibility.confirmPassword ? "text" : "password"}
            value={userData.confirmPassword}
            onChange={handleInputChange("confirmPassword")}
            fullWidth
            margin="normal"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    edge="end"
                    onClick={() => togglePasswordVisibility("confirmPassword")}
                  >
                    {passwordVisibility.confirmPassword ? (
                      <VisibilityIcon />
                    ) : (
                      <VisibilityOffIcon />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <Button
            variant="contained"
            color="primary"
            onClick={handleSaveChanges}
          >
            Save Changes
          </Button>
        </Box>
      </Modal>
    );
  };

  React.useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axiosWithToken.get(
          BASE_URL + `/users/${storage_userID}`
        );

        if (response.status === 200) {
          const userData = response.data;
          setUserData({
            username: userData[0].userName,
          });
        } else {
          console.error("Failed to fetch user data");
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchUserData();
  }, []);

  return (
    <>
      {passwordChangeModal()}
      {!isMobile ? (
        <Button onClick={handleModalOpen}>
          <RestartAltIcon style={{ marginRight: "10px" }} />
          Reset Password
        </Button>
      ) : (
        <ListItemButton>
          <ListItemIcon>
            <Tooltip title="Reset Password" arrow placement="right">
              <RestartAltIcon />
            </Tooltip>
          </ListItemIcon>
          <Typography
            variant="p"
            component="div"
            align="left"
            onClick={handleModalOpen}
          >
            {"Reset Password"}
          </Typography>
        </ListItemButton>
      )}
    </>
  );
}
