import { Button, Toolbar } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router";
import Sticker from "./sticker";

const MultipleStickers = () => {
  const location = useLocation();
  const [ids, setIds] = useState([]);
  const elementRef = useRef(null);

  const handlePrint = () => {
    const printContent = document.getElementById("formToPrint");
    const originalContents = document.body.innerHTML;

    if (printContent) {
      document.body.innerHTML = elementRef.current.innerHTML;
      window.print();
      document.body.innerHTML = originalContents;
      window.location.reload();
    }
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const idsParam = searchParams.get("ids");
    if (idsParam) {
      const idsArray = idsParam.split(",").map((id) => parseInt(id));
      setIds(idsArray);
    }
  }, [location.search]);

  return (
    <>
      <Toolbar
        style={{
          padding: "0px",
          overflow: "auto",
          display: "flex",
          justifyContent: "end",
        }}
      >
        <Button
          variant="contained"
          size="small"
          sx={{ marginRight: "20px" }}
          onClick={handlePrint}
        >
          Report Print
        </Button>
      </Toolbar>
      <div id="formToPrint" ref={elementRef}>
        {ids?.map((ele) => {
          return (
            <div 
            // style={{ pageBreakAfter: "always" }}
            >
              <Sticker selectedFormID={ele} />
            </div>
          );
        })}
      </div>
    </>
  );
};

export default MultipleStickers;
