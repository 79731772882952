import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router";
import axiosWithToken from "../../utils/components/axiosTokenConfig";
import { BASE_URL } from "../../global";
import FormPrint from "./formPrint";
import { Button, Toolbar } from "@mui/material";
import html2pdf from "html2pdf.js";
import html2canvas from "html2canvas";
import { toPng } from "html-to-image";
import headerImage from "../image/visibleSafety/VSS_LETTER_HEAD-removebg.png";
import footerImage from "../image/visibleSafety/footer_VCC1.png";
import vssLogo from "../image/visibleSafety/VSS_LOGO-removebg-preview.png";
import DownloadPdfLoaderModal from "../../utils/components/downloadPdfLoader";


const MultiplePrintForm = () => {
  const location = useLocation();
  const [ids, setIds] = useState([]);
  const [FormData, setFormData] = useState([]);
  const [downloadPdfloading, setDownloadPdfloading] = React.useState(false);
  const printComponentRef = useRef(null);

  const fetchFormData = () => {
    try {
      let SQLQuery = {
        query: `SELECT id, templateId FROM reportValues WHERE id IN (${ids.join(
          ","
        )}) AND approvedBy IS NOT NULL ORDER BY id DESC`,
      };
      

      axiosWithToken.post(BASE_URL + `dynamic`, SQLQuery).then((res) => {
        let data = res.data;
        setFormData(data);
      });
    } catch (e) {
      console.log("Failed to fetch checklist template: ", e);
    }
  };

  const handlePrint = () => {

    const printContent = document.getElementById("formToPrint");
    const originalContents = document.body.innerHTML;

    if (printContent) {
      document.body.innerHTML = printComponentRef.current.innerHTML;
      window.print();
      document.body.innerHTML = originalContents;
      window.location.reload();
    }
  };

  const generateDraftImage = async () => {
    let htmlString = `
    <div>
 <img src=${vssLogo} style="width:70%; height:600px; opacity:0.3"/>
        
    </div>`;
    let htmlNode = document.getElementById("header-section");
    htmlNode.innerHTML = htmlString;
    let image = await toPng(htmlNode, { quality: 2, scale: 3 });
    const img = new Image();
    img.src = image;
    htmlNode.innerHTML = "";
    return img;
  };

  const generateLetterHeadHeader = async () => {
    console.log("headerImage",headerImage)
    let htmlString = `
    <div>
      <img src=${headerImage} style="width:100%; height:170px;"/>
    </div>`;
    let div = document.createElement("div");
    div.innerHTML = htmlString;
    document.body.appendChild(div);
    let canvas = await html2canvas(div, { scale: 1 });
    const img = new Image();
    img.src = canvas.toDataURL();
    document.body.removeChild(div);
    return img;
  };

  const generateLetterHeadFooter = async () => {
    let htmlString = `
    <div>
       <img src=${footerImage} style="width:100%; height:150px;"/>
    </div>`;
    let div = document.createElement("div");
    div.innerHTML = htmlString;
    document.body.appendChild(div);
    let canvas = await html2canvas(div, { scale: 1 });
    const img = new Image();
    img.src = canvas.toDataURL();
    document.body.removeChild(div);
    return img;
  };

  const generatePDF = async () => {
    setDownloadPdfloading(true);
    try {
      const element = printComponentRef.current;
      let pdf = await html2pdf()
        .from(element)
        .set({
          margin: [100, 1, 40, 1],
          pagebreak: { after: "section", mode: ["css", "legacy"] },
          html2canvas: {
            dpi: 192,
            scale: 4,
            letterRendering: true,
            useCORS: true,
          },
          jsPDF: {
            orientation: "portrait",
            unit: "pt",
            format: "a4",
          },
        })
        .toPdf()
        .get("pdf");

      const totalPages = pdf.internal.getNumberOfPages();

      const watermarkImage = await generateDraftImage();

      for (let i = 1; i <= totalPages; i++) {
        pdf.setPage(i);
        pdf.setFontSize(9);
        pdf.setTextColor(0, 0, 0);
        pdf.setFont("Courier");


            // Add watermark image at the back of the page
            const pageWidth1 = pdf.internal.pageSize.getWidth();
            const pageHeight = pdf.internal.pageSize.getHeight();
            const imgWidth = 400;
            const imgHeight =
              (imgWidth * watermarkImage.height) / watermarkImage.width;
            const x1 = (pageWidth1 - imgWidth) / 2;
            const y1 = (pageHeight - imgHeight) / 2;
            pdf.addImage(watermarkImage.src, "PNG", x1, y1, imgWidth, imgHeight);

        // border add for contnet
        // const borderWidth = 0.6;
        // const leftMargin = 15;
        // const borderX = leftMargin;
        // const borderY = 5;
        // const rightMargin = 595 - leftMargin * 0.95;
        // pdf.setLineWidth(borderWidth);
        // pdf.rect(borderX, borderY, rightMargin - leftMargin, 750);



        let image = await generateLetterHeadHeader();
        pdf.addImage(image, 0, 0, 595, 100);

        image = await generateLetterHeadFooter();
        pdf.addImage(image, 0, 800, 595, 45);
       
      }

      const blob = pdf.output("blob");
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = `form.pdf`;
      a.click();

      setDownloadPdfloading(false);
    } catch (error) {
      console.error("Error generating PDF:", error);
      setDownloadPdfloading(false);
    }
  };

  useEffect(() => {
    if (ids?.length > 0) fetchFormData();
  }, [ids]);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const idsParam = searchParams.get("ids");
    if (idsParam) {
      const idsArray = idsParam.split(",").map((id) => parseInt(id));
      setIds(idsArray);
    }
  }, [location.search]);
  return (
    <>
      <Toolbar
            style={{
              padding: "0px",
              overflow: "auto",
              display: "flex",
              justifyContent: "end",
            }}
          >
            <Button
              variant="contained"
              size="small"
              sx={{ marginRight: "20px" }}
              onClick={generatePDF}
            >
              Print Reports
            </Button>
          </Toolbar>
    <div
     id="formToPrint"
     ref={printComponentRef}
    >
      {FormData?.map((ele) => {
        return (
          <div style={{ pageBreakAfter: 'always' }} >
            <FormPrint 
            selectedFormID ={ele?.id}
            selectedTemplateID={ele?.templateId}
            />
            
          </div>
        );
      })}
    </div>
    {downloadPdfloading && (
        <DownloadPdfLoaderModal loading={downloadPdfloading} name={"Pdf"} />
      )}
    </>
  );
};

export default MultiplePrintForm;
