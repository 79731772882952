
import axios from 'axios';
import { BASE_URL } from "../../global";

// Create axios instance directly
const axiosWithToken = axios.create({
  baseURL: BASE_URL,
});

// Add an interceptor to include the token in the headers
axiosWithToken.interceptors.request.use((config) => {
  const token = sessionStorage.getItem('authToken'); // Get the token from session storage
  if (token) {
    config.headers['authorization'] = `Bearer ${token}`; // Include the token in the headers
  }
  return config;
});

// Export axiosWithToken directly
export default axiosWithToken;
