import React, { useEffect } from "react";
import { BASE_URL, DOMAIN } from "./../../global";
import Tables from "../../utils/components/tables";
import moment from "moment";
import PreviewIcon from "@mui/icons-material/Preview";
import DeleteIcon from "@mui/icons-material/Delete";
import { ToastContainer, toast } from "react-toastify";
import { Button, TableContainer, Toolbar, TextField, Autocomplete } from "@mui/material";
import Paper from "@mui/material/Paper";
import { showComponent } from "../helper/helpers";
import { Link } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import axiosWithToken from "../../utils/components/axiosTokenConfig";

const columns = [
  { id: "id", label: "Sr. No.", minWidth: 20 },
  { id: "userName", label: "User Name", minWidth: 40 },
  { id: "password", label: "Password", minWidth: 40 },
  { id: "type", label: "Type", minWidth: 40 },
  // { id: "branches", label: "Branches", minWidth: 40 },
  { id: "modules", label: "Modules", minWidth: 60 },
];

const UserList = () => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [searchBy, setSearchBy] = React.useState("");
  const [searchKey, setSearchKey] = React.useState("");
  const [totalRows, setTotalRows] = React.useState(0);
  const _clientId = localStorage.getItem("clientId");
  const _userType = localStorage.getItem("type");
  const [from, setFrom] = React.useState(null);
  const [to, setTo] = React.useState(null);
  const [data, setFinalData] = React.useState([]);

  const getQuery = (count) => {
    let whereConditions = "";
    let pagination_settings = "";

    if (searchBy !== "" && searchKey != "") {
      let colName = {
        "User Name": "userName",
        // "Address": "address",
        // "Certificate Number": "certificateNumber",
      }[searchBy];
      whereConditions = `where ${colName} like '%${searchKey}%'`;
    }


    // if (_userType == 3)
    //   whereConditions +=
    //     whereConditions.length > 0
    //       ? `and cert.clientId = ${_clientId}`
    //       : `and cert.clientId = ${_clientId}`;
    // whereConditions +=
    //   whereConditions.length > 0
    //     ? " AND cert.status = 1"
    //     : "AND cert.status = 1";

    if (rowsPerPage != -1) {
      pagination_settings = `limit ${rowsPerPage} offset ${
        rowsPerPage * Number(page)
      }`;
    }

    let data = {
      query: `SELECT * from users  ${whereConditions}  order by id DESC ${pagination_settings}`,
    };

    if (count)
      data.query = `SELECT COUNT(*) AS no_of_rows FROM users  ${whereConditions}  order by id DESC ${pagination_settings}`;
    return data;
  };

  // api calls
  function getTotalRows() {
    let data = getQuery(true);

    axiosWithToken
      .post(BASE_URL + `dynamic`, data)
      .then(async (res) => {
        setTotalRows(res.data[0]?.no_of_rows);
      })
      .catch((err) => {
        console.log("datasheet data fetching error: ", err);
      });
  }

  function fetchFinalData() {
 
    let data = getQuery();

    axiosWithToken
      .post(BASE_URL + `dynamic`, data)
      .then((res) => {
        setFinalData(res.data)
      })
      .catch((err) => {
        console.log("  error: ", err);
      });
  }

  const search = () => {
    fetchFinalData();
  };

  const handleDelete = (id) => {
    let url = BASE_URL;
    axiosWithToken
      .delete(url + `users/${id}`)
      .then((res) => {
        toast("User is deleted!");
        setTimeout(refresh, 500);
      })
      .catch((error) => {
        toast.error("Something Went Wrong!");
      });
  };

  var refresh = () => {
    window.location.reload(false);
  };

  useEffect(() => {
    getTotalRows();
  }, []);

  useEffect(() => {
    fetchFinalData();
  }, [rowsPerPage,page]);

  const actions = [
    {
      id: "edit",
      tooltip: "Edit User",
      link: true,
      linkTo: (row) => `/editUser/${row.id}`,
      icon: <PreviewIcon />,
    },
    {
      id: "delete",
      tooltip: "Delete User",
      icon: <DeleteIcon style={{ color: "#dc3545" }} />,
      handler: (row)  => window.confirm('Are you sure you want to delete this?') && handleDelete(row?.id),

    },
  ];

  return (
    <div>
      <TableContainer component={Paper} sx={{ mt: 2 }}>
        <Toolbar>
          {showComponent("add") && (
            <Button
              variant="contained"
              size="small"
              component={Link}
              to="/editUser"
              // onClick={() => {
              //   setTitle("Master/ add new user");
              // }}
            >
              <b>ADD NEW</b>
            </Button>
          )}
        </Toolbar>
        <br />
        <div
        className="mb-2"
        style={{
          display: "flex",
          float: "left",
          justifyContent: "flex-end",
          width: "100%",
        }}
      >
        <div
          className="mb-2"
          style={{ display: "flex", float: "left", justifyContent: "flex-end" }}
        >
          <Autocomplete
            sx={{
              m: 0,
              minWidth: 180,
              marginLeft: "10px",
            }}
            size="small"
            id="combo-box-demo"
            options={
              _userType != 3
                ? [
                    { key: "userName", label: "User Name" },
                    // { key: "address", label: "Address" },
                    // { key: "certificateNumber", label: "Certificate Number" },
                  ]
                : [{ key: "name", label: "LAB Name" }]
            }
           
            renderInput={(params) => (
              <TextField {...params} label="Search By" />
            )}
            onInputChange={(event, newInputValue) => {
              setSearchBy(newInputValue);
            }}
          />
          <TextField
            id="outlined-basic"
            label={"enter " + searchBy}
            size="small"
            variant="outlined"
            onChange={(e) => setSearchKey(e.target.value)}
          />
          <Button
            variant="contained"
            size="small"
            sx={{ m: 0 }}
            style={{ marginLeft: "10px" }}
            onClick={() => {
              search();
            }}
          >
            <SearchIcon />
          </Button>
        </div>
        </div>
        <br />
        <Tables
          page={page}
          setPage={setPage}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          handleDelete={handleDelete}
          columns={columns}
          finalData={data}
          actions={actions}
          totalRows={totalRows}
        />
      </TableContainer>
    </div>
  )
}

export default UserList